.leaflet-control-minZoomIndicator {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  background: #fff;
  padding: 5px 14px;
  color: #333;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
